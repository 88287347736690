const referentsData = [
  {
    id: 1,
    title: 'Clau, Quiero Ser Ingeniera',
    text: 'Un podcast per inspirar i guiar joves cap a l\'enginyeria. Presentat per Clau, explora especialitats, desafiaments, oportunitats i consells pràctics amb entrevistes a professionals del sector.',
    img: 'clau_qsi.jpg',
    website: 'https://www.instagram.com/clau_qsi/'
  },
  {
    id: 2,
    title: 'No Me Da La Vida',
    text: 'Podcast centrat en conciliar vida personal i professional, especialment per a dones en STEM. Ofereix consells, anècdotes i entrevistes amb expertes que han trobat l\'equilibri ideal.',
    img: 'nomedalavidadev.jpg',
    website: 'https://www.instagram.com/nomedalavidadev/'
  },
  {
    id: 3,
    title: 'Alejandra Thomas',
    text: 'A través de vídeos i recursos, demostra que aprendre a programar és accessible i divertit, inspirant persones de totes les edats a endinsar-se en el món de la tecnologia.',
    img: 'pikacodes.jpg',
    website: 'https://www.instagram.com/pikacodes/'
  },
  {
    id: 4,
    title: 'Martinna',
    text: 'Enginyera biomèdica, Martina López inspira i guia dones en tecnologia, compartint recursos i experiències sobre com la programació transforma la salut i la innovació.',
    img: 'martinna.jpg',
    website: 'https://www.youtube.com/@Martinnalopez'
  },
  {
    id: 5,
    title: 'Christina Ernst',
    text: 'Enginyera de Software i fundadora de la plataforma SheBuildsRobots.org. Es dedica a ensenyar a noies a construir robots i a combinar la moda amb la tecnologia per inspirar la propera generació d\'innovadores',
    img: 'shebuildsrobots.jpg',
    website: 'https://www.instagram.com/shebuildsrobots/'
  },
  {
    id: 6,
    title: 'Rita Iglesias',
    text: 'Enginyera de Software a Topi, actriu i violinista ocasional. Comparteix contingut humorístic sobre programació a les seves xarxes socials, inspirant-ne d\'altres amb la seva versatilitat i creativitat.',
    img: 'rita.jpg',
    website: 'https://www.instagram.com/rita_codes/'
  },
  {
    id: 7,
    title: 'Carmen Santos',
    text: 'Enginyera de Software establerta a Londres, actualment treballant a Prime Video. Comparteix la seva passió per la programació, la lectura i el cafè a les seves xarxes socials.',
    img: 'csjack9.jpg',
    website: 'https://www.instagram.com/csjack9/'
  },
  {
    id: 8,
    title: 'Olesia',
    text: 'Desenvolupadora Full Stack que resideix a Nova Zelanda i treballa per a una empresa nord-americana. Comparteix la seva experiència en transició a una feina completament remota i ofereix consells sobre programació.',
    img: 'olesia.jpg',
    website: 'https://www.instagram.com/olesia_learns/'
  },
  {
    id: 9,
    title: 'Kirin Sinha',
    text: 'Fundadora i CEO d\'Illumix, una empresa líder en realitat augmentada. Amb una sòlida formació acadèmica que inclou estudis al MIT i Stanford, comparteix la seva passió per la tecnologia, les startups i l\'estil de vida com a CEO de Silicon Valley.',
    img: 'kirinsinha.jpg',
    website: 'https://www.instagram.com/kirinsinha/'
  },
  {
    id: 10,
    title: 'Olha',
    text: 'Desenvolupadora Front-End autodidacta i entusiasta de la tecnologia que comparteix el seu viatge per endinsar-se en la indústria tecnològica, viatjant i defensant la presència de les dones en el sector.',
    img: 'olha.jpg',
    website: 'https://www.instagram.com/olha.codes/'
  },
  {
    id: 11,
    title: 'Xyla Foxlin',
    text: 'Enginyera i emprenedora especialitzada en mecatrònica i tecnologia creativa. També és la directora executiva de Beauty and the Bolt, una organització que redueix les barreres d\'entrada per a dones i minories en els camps STEM.',
    img: 'xylafoxlin.jpg',
    website: 'https://www.instagram.com/xylafoxlin/'
  },
  {
    id: 12,
    title: 'Lidia Martínez',
    text: 'Experta en programació per a la indústria audiovisual, de videojocs, animació i VFX, treballant en pel·lícules com The Jungle Book i Deadpool. Comparteix tutorials i consells per a professionals de la programació i el desenvolupament de jocs.',
    img: 'lidia.jpg',
    website: 'https://www.youtube.com/@lidiamartinezespanol'
  }

]

export default referentsData;
import React, { useState } from "react";
import referentsData from './ReferentsData';

const ReferentsCards = () => {

  return (
    <div className="referents-cards-container">
      {referentsData.map((referent) => (
        <div className="referents-card-wrapper">
          <a href={referent.website} target="_blank" rel="noopener noreferrer">
            <div key={referent.id} className="referents-card">
              <img src={referent.img} alt={referent.title} />
              <h3>{referent.title}</h3>
              <p>{referent.text}</p>
            </div>
          </a>
        </div>
      ))}
    </div>
  );
};

export default ReferentsCards;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = ({ isMainHeader = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navTextColor = isMainHeader ? "#FFF4F3" : "#0632AF";

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <Link to="/">
        <div className="logo">
          <img src={isMainHeader ? 'logo_pink.png' : 'logo_blue.png'} alt="feimcodi" />
        </div>
      </Link>
      <div className="dropdown">
        <button onClick={toggleDropdown} className="dropdown-toggle">
          ☰
        </button>
        {isOpen && (
          <div className="dropdown-menu">
            <Link to="/" style={{ color: navTextColor }}>INICI</Link>
            <Link to="/info" style={{ color: navTextColor }}>INFORMA'T</Link>
            <Link to="/referents" style={{ color: navTextColor }}>REFERENTS</Link>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;

import React from 'react';
import { Link } from 'react-router-dom';

const Pagination = ({ prevPage, nextPage }) => {
  return (
    <div className="pagination">
      {prevPage && (
        <Link to={prevPage} className="pagination-link">
          Pàgina anterior
        </Link>
      )}
      {nextPage && (
        <Link to={nextPage} className="pagination-link">
          Pàgina següent
        </Link>
      )}
    </div>
  );
};

export default Pagination;

import { Suspense, React } from 'react';
import { Canvas } from '@react-three/fiber'
import { SoftShadows } from "@react-three/drei"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import PCExperience from './PCExperience.jsx'

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1>Descobreix el món de la Tecnologia i la Programació</h1>
        <p>Explora les oportunitats que ofereix, troba el teu camí i desenvolupa les teves habilitats amb recursos educatius i el suport de la nostra comunitat.</p>
        <Link to="/info">
          <button>COMENÇA</button>
        </Link>
      </div>
      <div className="hero-experience">
        <Canvas
          className="experience-canvas"
          style={{ height: '100%', width: '100%' }}
          camera={{
            fov: 45,
            near: 0.1,
            far: 2000,
            position: [0, 2.5, 5],
          }}
          shadows={true}
        >
          <Suspense fallback={<>Loading...</>}>
            <SoftShadows
              size={ 20 }
              samples={ 15 }
              focus={ 0 }
            />
            <PCExperience />
          </Suspense>
        </Canvas>
      </div>
      <div className="hero-social social-media-icon">
        <a href="https://www.instagram.com/feimcodi/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
      </div>
    </section>
  );
};

export default HeroSection;

import React from 'react';
import FeatureCard from './FeatureCard';

const features = [
  { icon: 'feat1.png', link:'/info', title: 'Informa\'t', description: 'Explora carreres i sortides en el camp de la tecnologia i la programació. Des de conèixer les diferents professions fins a descobrir indústries, empreses i projectes inspiradors, trobaràs tot el que necessites per a entendre les possibilitats d\'aquest món.', comingSoon: false },
  { icon: 'feat2.png', link:'/referents', title: 'Inspira\'t amb Referents', description: 'Descobreix perfils inspiradors de dones en el món de la tecnologia i la programació. Segueix els seus perfils en xarxes socials per a veure el seu dia a dia, com treballen i quins consells tenen per a oferir. Inspira\'t amb les seves històries i experiències mentre explores noves possibilitats.', comingSoon: false },
  { icon: 'feat3.png', link:'', title: 'Avalua el teu Potencial', description: 'Descobreix si les carreres tècniques són adequades per a tu i desafia els estereotips i mites comuns en el camp. Troba recursos i testimoniatges que t\'ajudaran a entendre millor què pots aconseguir i quines oportunitats t\'esperen.', comingSoon: true },
  { icon: 'feat4.png', link:'', title: 'Troba el teu Camí', description: 'Identifica les tecnologies que podries aprendre segons els teus interessos i les sortides professionals que t\'interessin. Obtingues orientació sobre quins llenguatges de programació i eines són rellevants en diferents àrees, i com poden ajudar-te a aconseguir les teves metes.', comingSoon: true },
  { icon: 'feat5.png', link:'', title: 'Accedeix a Recursos d’Aprenentatge', description: 'Explora una varietat de recursos educatius en línia, des de tutorials i cursos fins a plataformes d\'aprenentatge, que t\'ajudaran a adquirir habilitats i coneixements necessaris per a fer el teu camí en el camp de la tecnologia i la programació', comingSoon: true },
  { icon: 'feat6.png', link:'', title: 'Connecta\'t i Col·labora', description: 'Uneix-te a la nostra comunitat i col·labora amb al·lotes apassionades com tu. Fes preguntes, comparteix les teves experiències, troba companyes per a projectes i rep el suport que necessites per a créixer i avançar en el teu viatge tecnològic.', comingSoon: true },
];

const FeaturesSection = () => {
  return (
    <section className="features-section">
      <h2>
        Què pots fer a <br />
        FeimCodi?
      </h2>
      <div className="features-grid">
        {features.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </div>
    </section>
  );
};

export default FeaturesSection;
